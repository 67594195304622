/* eslint react/prop-types: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Blog from 'components/general/Blog';
import { Container, Grid, Typography, Box, Link } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from 'components/form/inputs/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        zIndex: theme.zIndex.modal + 2,
        color: '#fff',
        overflowY: 'auto'
    },
    ref: {
        textAlign: 'center'
    },
    favoriteButton: {
        '&:hover': {
            backgroundColor: 'transparent',
            color: `${theme.palette.secondary.main}!important`
        }
    },
    closeIcon: {
        position: 'absolute',
        top: '5%',
        right: '10%',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    tag: {
        fontSize: '1rem',
        color: '#6B6869',
        margin: '0px 3px'
    }
}));

function Preview(props) {
    const instance = useRef(null);
    const { t } = useTranslation('preview');
    const { open, handleClose, contest, changeContest, options, toggleFavorite, isFavorite, blog, isLikeable } = props;
    const classes = useStyles();
    const [platformIcon, setPlatformIcon] = useState('phone_iphone');
    const [forceMobile, setForceMobile] = useState(0);
    const [contestLink, setContestLink] = useState(`${process.env.REACT_APP_SHORT_DOMAIN}/contest-${contest.hashId}`);
    useEffect(() => {
        if (contest) {
            instance.current.innerHTML = '';
            const iframe = document.createElement('iframe');
            iframe.id = 'preview-iframe';
            iframe.src = `${contestLink}?force_mobile_render=${forceMobile}`;
            iframe.height = 700;
            if (forceMobile) {
                iframe.width = contest.width.mobile + 15;
                iframe.height = 600;
            } else {
                iframe.width = contest.width.desktop + 15;
            }
            instance.current.appendChild(iframe);

            setPlatformIcon(forceMobile ? 'desktop_windows' : 'phone_iphone');
        }
    }, [contestLink, forceMobile]);

    useEffect(() => {
        if(contest.isMinisite && contest.minisiteHashId !== '') {
            setContestLink(`${process.env.REACT_APP_SHORT_DOMAIN}/website/${contest.minisiteHashId}`);
        } else {
            setContestLink(`${process.env.REACT_APP_SHORT_DOMAIN}/contest-${contest.hashId}`);
        }
    }, [contest]);

    const toggleMobile = (event) => {
        event.stopPropagation();
        setForceMobile(forceMobile === 1 ? 0 : 1);
    };

    const getOptions = () => {
        const keys = Object.keys(contest.categories);
        return keys.map(key => {
            const categoryValue = options[key].find((value) => value.id === parseInt(contest.categories[key], 10));
            return {
                key: `${key}--${categoryValue.id}`,
                label: categoryValue.label
            };
        });

    };

    return (
        <Backdrop className={classes.backdrop} open={open} invisible={!open} onClick={handleClose} style={{ alignItems: 'normal' }} id="ContestPreview">
            <CloseIcon className={classes.closeIcon} onClick={handleClose} id="closePreviewButton"/>
            <Container maxWidth="lg" onClick={(e) => e.stopPropagation()}>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Grid container direction="row">
                                <Grid item xs={11} style={{ width: contest.width.desktop + 15, margin: 'auto', flexBasis: 'auto', marginBottom: 10 }}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid container item xs={1}>
                                            {blog && (
                                                <Blog />
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="h5" color="inherit">
                                                {contest.title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row">
                                                <Grid item>
                                                    <Avatar
                                                        src={`https://storage.kimple.co/kapmedia/assets/images/vendor/contest/templates/${contest.categories.template}.png`}
                                                    />
                                                </Grid>
                                                <Grid item style={{ marginLeft: 10, alignSelf: 'center' }}>
                                                    <Typography variant="subtitle1" color="inherit">
                                                        { contest.templateLabel }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row">
                                <Grid container item xs={1}>
                                    <Grid container direction="column" justify="center" alignItems="center">
                                        <Grid item>
                                            <IconButton
                                                name="previousContest"
                                                outline color="light"
                                                icon="arrow_back"
                                                tooltip={t('previous')}
                                                action={(e) => { e.stopPropagation(); changeContest(-1); }}
                                                id="previousContest"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={10} >
                                    {forceMobile === 1 &&
                                        <div id="iphone" style={{ marginBottom: 20 }}>

                                            {contest &&
                                                <div className={classes.ref} ref={instance} />
                                            }
                                        </div>
                                    }
                                    {contest && !forceMobile &&
                                        <div className={classes.ref} ref={instance} />
                                    }
                                    <Grid
                                        item xs={12} p={4}
                                        style={{ width: contest.width.desktop + 15, maxWidth: 1064, backgroundColor: '#343031', margin: 'auto' }}>
                                        <Box p={2} px={15} py={3}>
                                            <Grid container direction="row" id="contestDescription">
                                                {contest.description}
                                            </Grid>
                                            <Grid container direction="row" style={{ marginTop: 15 }} spacing={1}>
                                                { getOptions().map(option => 
                                                    <Typography key={option.key} className={classes.tag}>
                                                        {option.label}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item xs={12}
                                        style={{ width: contest.width.desktop + 15, maxWidth: 1064, backgroundColor: '#FFFFFF', color: '#343031', margin: 'auto' }}>
                                        <Box p={2}>
                                            <p style={{ fontSize: '1rem' }}>
                                                {t('legals')}
                                                <Link href={`${process.env.PUBLIC_URL}/assets/MENTIONSLEGALES.pdf`} target="_blank" color="inherit">
                                                    {t('link')}
                                                </Link>
                                            </p>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={1}>
                                    <Grid container direction="column" justify="center" alignItems="center">
                                        <Grid item xs={4}>
                                            {isLikeable && (
                                                <Grid item>
                                                    <IconButton
                                                        className={classes.favoriteButton}
                                                        name="favorite"
                                                        outline
                                                        color="light"
                                                        icon={isFavorite ? 'favorite' : 'favorite_border'}
                                                        action={(e) => {
                                                            e.stopPropagation();
                                                            toggleFavorite();
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <IconButton
                                                    name="platform"
                                                    color="primary"
                                                    icon={platformIcon}
                                                    action={toggleMobile}
                                                    id="toggleDevice"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    name="open"
                                                    color="primary"
                                                    icon="open_in_new"
                                                    action={(e) => { e.stopPropagation(); window.open(`${contestLink}`); }}
                                                    id="openInNew"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={4} justify="center" alignItems="center">
                                            <Grid item>
                                                <IconButton
                                                    name="nextContest"
                                                    outline color="light"
                                                    icon="arrow_forward"
                                                    tooltip={t('next')}
                                                    action={(e) => { e.stopPropagation(); changeContest(1); }}
                                                    id="nextContest"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item xs={4} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </Container>
        </Backdrop>
    );
}

Preview.defaultProps = {
};

Preview.propTypes = {
};

export default Preview;
