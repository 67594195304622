import propTypes from 'prop-types';
import React, { createContext, useEffect, useReducer, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import searchService from 'services/contest';
import searchReducer, { initialState, searchActions } from 'components/utils/reducers/search';
import { useContextUser } from 'components/utils/contexts/user/Context';
import Alert from 'components/general/Alert';
import store from 'config/store';
import routes from 'config/routes';

const SearchContext = createContext();

export function useSearchContext() {
  const context = React.useContext(SearchContext);
  if (context === undefined) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }
  return context;
}

export const SearchProvider = ({ children }) => {
  const { t } = useTranslation('templates');
  const persistedStore = store.get('search');
  const [state, dispatch] = useReducer(searchReducer, initialState);
  const { user, addFavorite, removeFavorite } = useContextUser();
  const [favError, setFavError] = useState(false);
  const location = useLocation();
  const { results, filters, initialized, loading, error, search, last, defaultOptions, relatedContent } = state;
  const formatContest = (contests, likes) => {
    return contests.map(contest => {
      const defaultShowcase = `https://storage.kimple.co/kapmedia/assets/images/vendor/contest/templates/${contest.template}.png`;
      const rootCategories = {
        activity_area: contest.activity_area,
        template: contest.template
      };

      const extendedCategories = contest.metas
        ? contest.metas
          // eslint-disable-next-line
          .filter(meta => ['marketing_goal', 'year_highlight'].includes(meta._key))
          // eslint-disable-next-line
          .reduce((acc, meta) => ({ ...acc, [meta._key]: parseInt(meta.value, 10) }), rootCategories)
        : rootCategories;
        
      const isMinisite = contest.metas
        // eslint-disable-next-line
        ? contest.metas.find(meta => meta._key === 'is_minisite')
        : 0;

      const minisiteHashId = contest.metas
        // eslint-disable-next-line
        ? contest.metas.find(meta => meta._key === 'minisite_hash_id')
        : null;

        // eslint-disable-next-line
        const showcaseImage = contest.showcase_image || defaultShowcase;

      return {
        type: 1,
        hashId: contest.hash_id,
        title: contest.title,
        description: contest.description,
        height: {
          desktop: contest.height_desktop,
          facebook: contest.height_facebook,
          mobile: contest.height_mobile,
        },
        width: {
          desktop: contest.width_desktop,
          facebook: contest.width_facebook,
          mobile: contest.width_mobile,
        },
        hideFromInspiration: contest.hide_from_inspiration,
        contentLink: contest.content_link,
        categories: extendedCategories,
        isLiked: likes.includes(contest.hash_id),
        publishedAt: Date.parse(contest.published_at),
        showcaseImage,
        isMinisite : isMinisite ? isMinisite.value : 0,
        minisiteHashId : minisiteHashId ? minisiteHashId.value : '',
        template: contest.template,
        templateLabel: t(`${contest.template}`) || ''
      };

    });
  };

  // Actions creators
  const initSearch = async () => {
    dispatch(searchActions.initSearchFetching());
    if (persistedStore) {
      dispatch(searchActions.initSearchSuccess({ ...persistedStore, route: location.pathname }));
    } else {
      try {
        const [optionsRes, contestsRes, relatedContentRes] = await Promise.all([
          searchService.getOptions(),
          searchService.getContests(),
          searchService.getRelatedContent()
        ]);
        const contests = contestsRes.data.data;
        const sponsoredContent = relatedContentRes.data.data;
        const likes = user.data && user.data.contest_like || [];
        const updatedContests = formatContest(contests, likes);

        const payload = {
          options: optionsRes.data.data,
          contests: updatedContests,
          wishlist: updatedContests.filter(contest => contest.isLiked),
          relatedContent: sponsoredContent
        };

        store.set('search', payload);
        dispatch(searchActions.initSearchSuccess({ ...payload, route: location.pathname }));
      } catch (fetchError) {
        dispatch(searchActions.initSearchFailed(fetchError));
      }
    }
  };

  const triggerSearch = (term) => {
    dispatch(searchActions.resetFilters());
    dispatch(searchActions.triggerSearch(term));
    if (term !== '') dispatch(searchActions.addSearchHistory(term));
  };

  const triggerAdvancedSearch = () => {
    dispatch(searchActions.updateResults());
    dispatch(searchActions.updateFilters());
  };

  const toggleFilter = (category, filterId) => {
    dispatch(searchActions.toggleFilter(category, filterId));
    dispatch(searchActions.updateResults());
    dispatch(searchActions.updateFilters());
  };

  const resetCategory = (category) => {
    dispatch(searchActions.resetCategory(category));
    dispatch(searchActions.updateResults());
    dispatch(searchActions.updateFilters());
  };

  const saveSearchTerm = (term) => {
    dispatch(searchActions.saveSearchTerm(term));
  };

  const toggleFavorite = (contest, toggled) => {
    const request = toggled ? addFavorite(contest.hashId) : removeFavorite(contest.hashId);
    request.then(() => {
      dispatch(searchActions.updateWishlist(contest));
      dispatch(searchActions.updateStore());
    })
      .catch(() => {
        setFavError(true);
      });
  };

  const resetFilters = () => {
    dispatch(searchActions.resetFilters());
  };

  const sortBy = (key, order) => {
    dispatch(searchActions.sortBy(key, order));
  };

  const firePredictions = (term) => {
    dispatch(searchActions.firePredictions(term));
  };

  const clearPredictions = () => {
    dispatch(searchActions.clearPredictions());
  };

  useEffect(() => {
    if ([routes.WISHLIST, routes.BROWSE].includes(location.pathname)) {
      initSearch();
    }
  }, [location]);

  const handleClose = () => {
    setFavError(false);
  };

  const mapStateToProps = { results, filters, initialized, loading, error, search, last, defaultOptions, relatedContent };
  const mapDispatchToProps = { triggerSearch, toggleFilter, resetCategory, toggleFavorite, resetFilters, sortBy, saveSearchTerm, triggerAdvancedSearch, firePredictions, clearPredictions };

  return (
    <SearchContext.Provider value={{ ...mapStateToProps, ...mapDispatchToProps }}>
      { children }
      <Snackbar open={favError} autoHideDuration={3000} onClose={handleClose}>
        <Alert severity="error" message="An error occured while trying to add this contest in your wishlist !" />
      </Snackbar>
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: propTypes.element.isRequired
};

export default {
  useSearchContext,
  SearchProvider
};
