import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardMedia, Avatar, Grid, Typography } from '@material-ui/core';

import Button from 'components/form/inputs/Button';
import FavoriteButton from 'components/form/inputs/FavoriteButton';
import Blog from 'components/general/Blog';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    cardContent: ({ type }) => ({
        padding: type === 1 ? '10px 0px 16px' : '18px 0px 16px'
    }),
    card: ({ type }) => ({
        boxShadow: 'none',
        cursor: type === 1 ? 'pointer' : 'initial',
        position: 'relative',
        '&:hover .cardImage': {
            filter: 'brightness(0.5)'
        }
    }),
    blog: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
    },
    image: {
        borderRadius: 4,
        boxShadow: '0px 4px 8px -4px #000000'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: 10
    },
    templateName: {
        alignSelf: 'center'
    }
}));

function CustomCard(props) {
    const { isFavorite, title, template, image, onClick, toggleFavorite, blog, isLikeable, type, id, templateLabel, cta } = props;
    const classes = useStyles({ type });
    const baseName = type === 1 ? 'ContestCardItem' : 'RelatedContentItem';
    return (
        <Card className={clsx(classes.card, baseName)} onClick={type === 1 ? onClick : null} name={`card-${id}`}>
            {blog && (
                <Blog
                    className={clsx(`${classes.blog} cardBlog`)}
                    link={blog}
                />
            )}
            <CardMedia
                className={clsx(`${classes.image} cardImage`)}
                component="img"
                height="200"
                image={image}
            />
            <CardContent classes={{ root: classes.cardContent }}>
                <Grid container direction="row" justify="space-between" alignItems="baseline">
                    <Grid item xs>
                        <Typography variant="body2" color="textPrimary" gutterBottom className="contest--title">
                            {title}
                        </Typography>
                    </Grid>
                    {isLikeable &&
                        <Grid item xs="auto">
                            <FavoriteButton
                                color="secondary"
                                checked={isFavorite}
                                onChange={toggleFavorite}
                            />
                        </Grid>
                    }
                </Grid>
                {type !== 2 ? (
                    <Grid container direction="row">
                        <Grid item xs="auto">
                            <Avatar
                                src={`https://storage.kimple.co/kapmedia/assets/images/vendor/contest/templates/${template}.png`}
                                className={classes.small}
                            />
                        </Grid>
                        <Grid item className={classes.templateName}>
                            <Typography variant="subtitle2" color="textSecondary" className="contest--template">
                                { templateLabel }
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                        <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            label={cta}
                            fullWidth
                            size="small"
                            onClick={onClick}
                            style={{margin: 0, padding: '2px 10px'}}
                        />
                    )
                }
            </CardContent>
        </Card>
    );
}

CustomCard.defaultProps = {
    id: '',
    isFavorite: false,
    isLikeable: false,
    blog: '',
    toggleFavorite: () => null,
    onClick: () => null,
    template: null,
    templateLabel: ''
};

CustomCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    type: PropTypes.number.isRequired,
    cta: PropTypes.string.isRequired,
    id: PropTypes.string,
    template: PropTypes.number,
    isFavorite: PropTypes.bool,
    onClick: PropTypes.func,
    toggleFavorite: PropTypes.func,
    blog: PropTypes.string,
    isLikeable: PropTypes.bool,
    templateLabel: PropTypes.string
};

export default CustomCard;
